import React from 'react';
import {
    Box,
    Button,
    useSteps,
    Flex, Card, CardBody, useColorModeValue, Circle, IconButton,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import {useAppSelector} from "../../hooks/useAppSelector";
import {RootState} from "../../store/store";
import {useAppDispatch} from "../../hooks/useAppDispatch";
import {updateUserDetails} from "../../store/user/userSlice";
import {FormattedMessage} from "react-intl";
import {Tag, User} from "../../types";
import ProfileStep from "./ProfileStep";
import { FaArrowLeft } from 'react-icons/fa';
import NewsLetterStep from "./NewsLetterStep";
import TagSelectionStep from "./TagSelectionStep";
import {useNavigate} from "react-router-dom";

const steps = [
    { code: 'language_and_country' },
    { code: 'personal_details' },
    // { code: 'interests'},
    { code: 'newsletter'},
];

const CompleteProfile = () => {
    const dispatch = useAppDispatch();
    const bgColor = useColorModeValue('light.cardBackground', 'dark.cardBackground');
    const user = useAppSelector((state: RootState) => state.user.user);
    const navigate = useNavigate();
    const { activeStep, goToNext, goToPrevious } = useSteps({
        index: 0,
        count: steps.length,
    });

    const [stepData, setStepData] = React.useState<Partial<User>>({});

    const handleStepDataChange = (updatedData: Partial<User>) => {
        setStepData((prevData) => ({
            ...prevData,
            ...updatedData,
        }));
    };

    const handleNext = () => {
        switch (activeStep) {
            case 0:
                
                dispatch(updateUserDetails({ ...user, ...stepData}));
                break;
            case 1:
                const tags: Partial<Tag>[] | undefined = stepData.interests;
                dispatch(updateUserDetails({ interests: tags }));
                break;
            case 2:
                dispatch(updateUserDetails({newsletter: stepData.newsletter, isNewUser: false }));
                navigate('/');
                break;
            default:
                break;
        }

        if (activeStep < steps.length - 1) {
            goToNext();
        }
    };

    const handleBack = () => {
        if (activeStep > 0) {
            goToPrevious();
        }
    };

    const stepVariants = {
        initial: { opacity: 0, x: 100 },
        animate: { opacity: 1, x: 0, transition: { duration: 0.5 } },
        exit: { opacity: 0, x: -100, transition: { duration: 0.5 } },
    };

    return (
        <Box width="100%" display="flex" justifyContent="center" alignItems="center">
            <Card
                p={[0, 4]}
                id="basic-info"
                bgColor={bgColor}
                width={['90%', '80%']}
                height={['auto', '100vh']}
                overflowY="auto"
            >
                <CardBody flex="1" display="flex" justifyContent="center" alignItems="center">
                    <motion.div
                        key={activeStep} // Ensure animation on step change
                        variants={stepVariants}
                        initial="initial"
                        animate="animate"
                        exit="exit"
                        style={{ width: '100%' }}
                    >
                        {activeStep === 0 && <ProfileStep handleStepDataChange={handleStepDataChange}/>}
                        {activeStep === 1 && <TagSelectionStep handleStepDataChange={handleStepDataChange}/>}
                        {/*{activeStep === 2 && <FollowingStep/>}*/}
                        {activeStep === 2 && <NewsLetterStep handleStepDataChange={handleStepDataChange}/>}
                    </motion.div>
                </CardBody>

                    <Flex justifyContent="space-between" alignItems="center" width="100%">
                        {/* Back arrow button */}
                        {activeStep !== 0 && (
                            <IconButton
                                onClick={handleBack}
                                icon={<FaArrowLeft />}
                                aria-label="Back"
                                variant="ghost"  // Removes background box
                                _focus={{ boxShadow: "none" }}  // Removes focus outline
                                size="lg"  // Adjust size
                                isDisabled={activeStep === 0}  // No need since we hide it
                            />
                        )}

                        {/* Spacer to take up space when Back button is hidden */}
                        {activeStep === 0 && <div style={{ width: "40px" }}></div>}

                        {/* Dot indicator, always centered */}
                        <Flex justifyContent="center" alignItems="center" mt={4} mb={8} flex="1">
                            {steps.map((_, index) => (
                                <Circle
                                    key={index}
                                    size="10px"
                                    bg={activeStep === index ? "blue.500" : "gray.300"}
                                    mx={1}
                                />
                            ))}
                        </Flex>

                        <Button onClick={handleNext}>
                            {activeStep === steps.length - 1 ? (
                                <FormattedMessage id="finish" defaultMessage="Finish" />
                            ) : (
                                <FormattedMessage id="continue" defaultMessage="Continue" />
                            )}
                        </Button>
                    </Flex>
            </Card>
        </Box>
    );
};

export default CompleteProfile;
