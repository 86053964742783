import React, {useEffect, useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {
    Box,
    Text,
    Flex,
    Heading,
    SimpleGrid, Link, Divider, Spinner, VStack, Avatar, Image, HStack,
} from "@chakra-ui/react";
import {News, Comment} from "../../types";
import { startLoading, stopLoading } from "../../store/loading/loadingSlice";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import {useAppSelector} from "../../hooks/useAppSelector";
import axiosInstance from "../../utils/axiosInstance";
import Preview from "../../components/Preview";
import {refreshIdToken} from "../../store/auth/authSlice";
import {FormattedMessage} from "react-intl";
import {timeAgo} from "../../utils/dateUtils";
import CommentBox from "./CommentBox";

const NewsDetails: React.FC = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { news_id } = useParams<{ news_id: string }>();
    const [newsData, setNewsData] = useState<News | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [isUserPost, setIsUserPost] = useState(false);
    const [relatatedArticles, setRelatedArticles] = useState<News[]>([]);
    const [isLoadingRelated, setIsLoadingRelated] = useState<boolean>(false); // Loading for related articles
    const [comments, setComments] = useState<Comment[]>([]);

    const idToken = useAppSelector((state) => state.auth.idToken);

    const location = useLocation();

    const onPostComment = (comment: Comment) => {
        setComments((prev) => [...prev, comment]);
    }
    useEffect(() => {
        window.scrollTo(0, 0);  // Scroll to top
    }, [location]);

    useEffect(() => {
            dispatch(refreshIdToken())
    }, [dispatch]);

    useEffect(() => {
        const fetchNews = async () => {
            try {
                dispatch(startLoading());
                const headers = {
                    Authorization: `Bearer ${idToken}`,
                    'Content-Type': 'application/json',
                }
                const response = await axiosInstance.get(`api/v1/news/${news_id}`, {headers});
                const data: News = await response.data;
                
                setNewsData(data);
                setComments(data.comments ?? []);
                setIsUserPost(data.type === "USER_POST")
            } catch (err) {
                setError(err instanceof Error ? err.message : "Unknown error occurred.");
            } finally {
                dispatch(stopLoading());
            }
        };

        fetchNews();
    }, [news_id]);
    useEffect(() => {
        const fetchRelatedNews = async () => {
            try {
                setIsLoadingRelated(true);
                const headers = {
                    Authorization: `Bearer ${idToken}`,
                    'Content-Type': 'application/json',
                }
                const response = await axiosInstance.get(`api/v1/news/related/${news_id}`, {headers});
                const data: News[] = await response.data;
                setRelatedArticles(data);
            } catch (err) {
                console.error(err instanceof Error ? err.message : "Unknown error occurred.");
            } finally {
                setIsLoadingRelated(false);
            }
        };
        fetchRelatedNews();
    }, [news_id]);

    if (error) {
        return (
            <Flex justify="center" align="center" minH="100vh">
                <Text color="red.500" fontSize="lg">
                    {error}
                </Text>
            </Flex>
        );
    }

    if (!newsData) {
        return (
            <Flex justify="center" align="center" minH="100vh">
                <Text color="gray.500" fontSize="lg">
                    No news data found.
                </Text>
            </Flex>
        );
    }

    return (
        <SimpleGrid
            columns={{ base: 1, md: 12 }}
            spacing={{ base: 2, md: 6 }}
            mx="auto"
            p={{ base: 2, md: 4 }}
        >
            <Box
                gridColumn={{ base: "span 1", md: "span 9" }}
                top="20px"
            >
                <Preview
                    newsData={newsData}
                    isUserPost={isUserPost}
                />

                <Box
                    borderRadius={{ base: "0", md: "md" }}
                    w="full"
                    p={{ base: 2, md: 4 }}
                    px={{ base: 2, md: 16 }}
                >
                    <Heading
                        as="h1"
                        size={{ base: "lg", md: "xl" }}
                        mb={5}
                        textAlign="left"
                    >
                        <FormattedMessage id="comments" defaultMessage="Comments"/> ({comments.length})
                    </Heading>
                    <VStack spacing={4} align="left">

                        <CommentBox news_id={news_id} onPostComment={onPostComment}/>

                        {comments.length > 0 ? (
                            comments.map((comment) => (
                                <Box>
                                    <Flex align="center" mb={{ base: 4, md: 0 }}> {/* Add margin-bottom on mobile */}
                                        <Avatar
                                            size="sm"
                                            name={comment.userName}
                                            mr={4}
                                            src={comment.profilePicUrl}
                                        />
                                        <Flex direction="column" justify="center">
                                            <Text
                                                fontSize="sm"
                                                fontWeight="medium"
                                                textAlign="left"
                                                _hover={{ color: "blue.500" }}
                                                onClick={() => navigate(comment.profileUrl)}
                                            >
                                                {comment.userName}
                                            </Text>
                                            <Text fontSize="xs" fontWeight="light" color="black" textAlign="left">
                                                {timeAgo(comment.updatedAt)}
                                            </Text>
                                        </Flex>
                                    </Flex>
                                    <Text fontSize="md" textAlign="justify" noOfLines={3}>
                                        {comment.content}
                                    </Text>
                                    <Divider mt={5} mb={5}/>
                                </Box>
                            ))
                        ) : (
                            <Text><FormattedMessage id="no_comments_yet" defaultMessage="No comments yet. Be the first to comment!"/></Text>
                        )}
                    </VStack>
                </Box>

            </Box>

            <Box
                gridColumn={{ base: "span 1", md: "span 3" }} // Takes 3 columns
                p={{ base: 0, md: 4 }}
                display={{ base: "none", md: "block" }}
            >
                <Heading as="h2" size="md" mb={4} fontWeight="bold">
                    More from {isUserPost ? newsData.author : newsData.sourceName}
                </Heading>
                {isLoadingRelated ? (
                    <Flex justify="center" align="center" height="100px">
                        <Spinner size="md" color="gray.500" />
                    </Flex>
                ) : (
                    relatatedArticles.map((article) => (
                        <Box key={article.id} mb={6}>
                            <HStack
                                spacing={2}
                                align="start"
                                _hover={{ cursor: "pointer" }}
                                onClick={() => navigate(`/news/${article.id}`)}
                            >
                                <Image
                                    src={article.imageUrl || '/placeholder.png'}
                                    alt={article.title}
                                    boxSize={{ base: "50px", md: "70px" }}
                                    objectFit="cover"
                                    borderRadius="sm"
                                    transition="transform 0.2s"
                                    _hover={{ transform: "scale(1.05)" }}
                                />
                                <Link href={"/news/" + article.id} isExternal>
                                    <Text fontSize="md" mb={1} textAlign="justify" noOfLines={3}>
                                        {article.title}
                                    </Text>
                                </Link>
                            </HStack>
                            <Text fontSize="sm" color="gray.500" textAlign="left">
                                {article.tags.map((tag) => `#${tag.name} `)}
                            </Text>
                            <Divider mt={2} />
                        </Box>
                    ))
                )}
            </Box>
        </SimpleGrid>
    );
};

export default NewsDetails;
