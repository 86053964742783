import React from 'react';
import {Route, Routes, Navigate, useLocation} from 'react-router-dom';
import { AuthUser } from "aws-amplify/auth";
import Home from "./Home/Home";
import Header from "../components/Header/Header";
import {Login} from "./Login/Login";
import {SignUp} from "./SignUp/SignUp";
import {ForgotPassword} from "./ForgotPassword/ForgotPassword";
import Profile from "./Profile";
import ProfileSettings from "./ProfileSettings";
import {ResetPassword} from "./ForgotPassword/ResetPassword";
import Economy from "./Economy/Economy";
import CompleteProfile from "./CompleteProfile/CompleteProfile";
import {Verification} from "./SignUp/Verification";
import DailyQuest from "./DailyQuest";
import Politics from "./Politics/Politics";
import Technology from "./Technology/Technology";
import Entertainment from "./Entertainment/Entertainment";
import Sports from "./Sports/Sports";
import RealEstate from "./RealEstate/RealEstate";
import Health from "./Health/Health";
import Travel from "./Travel/Travel";
import NewsDetails from "./NewsDetails";
import UserDetailsPage from "./UserDetailsPage";
import EditorComponent from "../components/EditorComponent";
import PrivacyPolicy from "./PrivacyPolicy";
import SubCategoryNewsList from "./SubCategoryNewsList";
import UserHome from "./Home/UserHome";
import CountryNewsPage from "./CountryNews";

interface HomeProps {
    user?: AuthUser
    signOut?: any
}

const Page = ({user, signOut}: HomeProps) => {
    const location = useLocation();
    const hideHeaderAndFooter = ['/complete-profile', '/create-article'].includes(location.pathname);

    return (
        <>
            {!hideHeaderAndFooter && <Header handleToggle={undefined} signOut={signOut}/>}
            <Routes>
                <Route path="/" element={<Navigate to="/news/home" />} />
                <Route path="/news/home" element={<UserHome/>} />
                <Route path="/news/top-stories" element={<Home/>} />
                <Route path="/news/economy" element={<Economy/>} />
                <Route path="/news/politics" element={<Politics/>} />
                <Route path="/news/lifestyle" element={<Entertainment/>} />
                <Route path="/news/sports" element={<Sports/>} />
                <Route path="/news/real-estate" element={<RealEstate/>} />
                <Route path="/news/health" element={<Health/>} />
                <Route path="/news/travel" element={<Travel/>} />
                <Route path="/news/country/:countryCode" element={<CountryNewsPage/>} />
                <Route path="/news/tech" element={<Technology/>} />
                <Route path="/news/subcategory/:subCategory" element={<SubCategoryNewsList/>} />
                <Route path="/news/daily_quest" element={<DailyQuest/>} />
                <Route path="/login" element={<Login/>}/>
                <Route path="/register" element={<SignUp/>}/>
                <Route path="/forgot-password" element={<ForgotPassword/>}/>
                <Route path="/confirm-password" element={<ResetPassword/>}/>
                <Route path="/profile" element={<Profile/>}/>
                <Route path="/profile-settings" element={<ProfileSettings/>}/>
                <Route path="/create-article" element={<EditorComponent />} />
                <Route path="/complete-profile" element={<CompleteProfile />} />
                <Route path="/verify" element={<Verification />} />
                <Route path="/news/:news_id" element={<NewsDetails />} />
                <Route path="/user/:id" element={<UserDetailsPage />} />
                <Route path="/organisation/:id" element={<UserDetailsPage />} />
                <Route path="/privacy" element={<PrivacyPolicy />} />
            </Routes>
        </>
    );
}

export default Page;