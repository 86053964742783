import React, { useState } from 'react';
import { HStack, Heading, Link, useColorModeValue, Box } from '@chakra-ui/react';

interface SubCategoryHeadingProps {
    subCategoryDisplay: string;
    subCategoryCode: string;
}

const SubCategoryHeading: React.FC<SubCategoryHeadingProps> = ({ subCategoryDisplay, subCategoryCode }) => {
    const [isHovered, setIsHovered] = useState(false);
    const textColor = useColorModeValue('light.text', 'dark.text');
    const hoverColor = useColorModeValue('light.accent', 'dark.accent');

    return (
        <Box>
            <Link
                href={`/news/subcategory/${subCategoryCode}`}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                _hover={{ textDecoration: "none" }}
            >
                <HStack spacing={2} align="center">
                    <Heading
                        as="h2"
                        fontSize="2xl"
                        borderLeftWidth="6px"
                        borderLeftColor={isHovered ? hoverColor : textColor}
                        color={isHovered ? hoverColor : textColor}
                        pl={4}
                        marginTop={10}
                        marginBottom={5}
                    >
                        {subCategoryDisplay}
                    </Heading>
                </HStack>
            </Link>
        </Box>
    );
};

export default React.memo(SubCategoryHeading);
