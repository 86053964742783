import {
    Box,
    Button,
    Flex,
    Textarea,
    IconButton,
    useDisclosure,
    Collapse,
    HStack,
    Text
} from "@chakra-ui/react";
import React, { useState } from "react";
import axiosInstance from "../../utils/axiosInstance";
import {useAppSelector} from "../../hooks/useAppSelector";
import {Comment} from "../../types";
import {FormattedMessage} from "react-intl";

interface CommentBoxProps {
    news_id?: string;
    onPostComment: (comment: Comment) => void;
}

const CommentBox:  React.FC<CommentBoxProps> = ({news_id, onPostComment}) => {
    const { isOpen, onToggle } = useDisclosure(); // Manages the animation state
    const [comment, setComment] = useState("");
    const [showPrompt, setShowPrompt] = useState(true);
    const idToken = useAppSelector((state) => state.auth.idToken);

    const handleCancel = () => {
        onToggle(); // Trigger the collapse animation
        setTimeout(() => setShowPrompt(true),100); // Show prompt after animation ends
    };

    const handleAddComment = async () => {
        if (!comment.trim()) return; // Prevent empty comments
        try {
            const headers = {
                Authorization: `Bearer ${idToken}`,
                "Content-Type": "application/json",
            };
            const response = await axiosInstance.post(
                `api/v1/news/comment`,
                { news_id: news_id, content: comment },
                { headers }
            );
            onPostComment(response.data)
            setComment(""); // Clear the input box
        } catch (err) {
            console.error(err instanceof Error ? err.message : "Error adding comment");
        }
    };

    return (
        <Box borderRadius="md">
            {showPrompt && !isOpen && (
            <Flex
                as="button"
                borderRadius="md"
                onClick={() => {
                    setShowPrompt(false);
                    onToggle();
                }}
                w="100%"
                alignItems="center"
                justifyContent="space-between"
                p={2}
            >
                <Text fontWeight="light"><FormattedMessage id="what_r_yr_thoughts" defaultMessage="What are your thoughts?"/></Text>
                <Button
                    size="sm"
                    isDisabled
                    variant="solid"
                    cursor="not-allowed"
                >
                    <FormattedMessage id="comment" defaultMessage="Comment"/>
                </Button>
            </Flex>
            )}
        <Collapse in={isOpen} animateOpacity>
            <Box>
                <Textarea
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    placeholder="What are your thoughts?"
                    resize="none"
                    mt={2}
                />
                {/* Buttons aligned to the right */}
                <Flex justifyContent="flex-end" alignItems="center" mt={2}>
                    <HStack spacing={3}>
                        <Button variant="ghost" size="sm" onClick={handleCancel}>
                            <FormattedMessage id="cancel" defaultMessage="Cancel"/>
                        </Button>
                        <Button colorScheme="purple" size="sm" isDisabled={!comment.trim()} onClick={handleAddComment}>
                            <FormattedMessage id="post_comment" defaultMessage="Post Comment"/>
                        </Button>
                    </HStack>
                </Flex>
            </Box>

        </Collapse>
    </Box>
);
};

export default CommentBox;
