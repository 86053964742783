import React from 'react';
import { Button, useColorModeValue, useTheme } from "@chakra-ui/react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";

export const AuthButton = () => {
    const theme = useTheme();
    const navigate = useNavigate();

    const color = useColorModeValue(theme.colors.gray[500], "white");
    const textColor = useColorModeValue("black", "white");

    const handleLoginClick = () => navigate("/login");

    return (
        <Button
            onClick={handleLoginClick}
            bg="transparent"
            color={color}
            size="sm"
            borderRadius="5px"
            textColor={textColor}
            _hover={{
                bg: "transparent",  // Ensures the background remains unchanged
                transform: "scale(1.05)", // Slight zoom effect on text
                transition: "transform 0.2s ease-in-out",
            }}
            _active={{
                bg: "transparent",  // Keeps background unchanged when clicked
            }}
        >
            <FormattedMessage id="login" defaultMessage="Sign in" />
        </Button>
    );
};
