import React from 'react';
import { Box, SimpleGrid } from '@chakra-ui/react';
import NewsCard from '../NewsCard/NewsCard';
import SubCategoryHeading from "./SubCategoryHeading";
import {News} from "../../types";

interface SubCategoryNewsGridProps {
    subCategoryDisplay: string;
    subCategoryCode: string;
    newsItems: News[];
    numLines?: number;
}

const SubCategoryNewsGrid: React.FC<SubCategoryNewsGridProps> = ({ subCategoryDisplay, subCategoryCode, newsItems, numLines = 2 }) => {
    return (
        <Box>
            <SubCategoryHeading subCategoryDisplay={subCategoryDisplay} subCategoryCode={subCategoryCode} />
            <SimpleGrid columns={{ base: 1, md: 4 }} spacing={10}>
                {newsItems.map((item, index) => (
                    <Box key={index}>
                        <NewsCard news={item} noOfLines={numLines} imageHeight={'300'} />
                    </Box>
                ))}
            </SimpleGrid>
        </Box>
    );
};

export default React.memo(SubCategoryNewsGrid);
