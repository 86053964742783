import {useLocation, useMatch, useParams} from 'react-router-dom';
import React, {useEffect, useLayoutEffect, useState} from "react";
import {Country, NewsResponse} from "../../types";
import {
    Avatar,
    Box,
    Center, Heading, SimpleGrid,
    useColorModeValue
} from '@chakra-ui/react'
import {useQuery} from "../../hooks/useQuery";
import NewsCard from "../../components/NewsCard/NewsCard";

function CountryNewsPage() {
    const { countryCode } = useParams<{ countryCode: string }>();
    const location = useLocation();

    const { data: newsData, loading: newsLoading, error: newsError, refresh: refreshNews } = useQuery<NewsResponse>({
        url: `/api/v1/getFeedByCountry/${countryCode}/TOP-STORIES`,
        page: {page: 0, size: 20}
    });
    const { data: countryData, loading: countryLoading, error: countryError, refresh: refreshCountry } = useQuery<Country>({ url: `/api/v1/country/${countryCode}` });

    useEffect(() => {
        window.scrollTo(0, 0);  // Scroll to top
    }, [location]);


    return (
        <Box minH="100vh" pt={8}>
            <Center py={6}>
                <Box
                    maxW={'100%'}
                    w={'full'}
                    bg={useColorModeValue('white', 'gray.900')}
                    boxShadow={'lg'}
                    rounded={'lg'}
                    p={6}
                    pt={16} // Add padding top to make space for Avatar
                    textAlign={'center'}
                    position="relative" // Required for absolute positioning
                >
                    {/* Avatar */}
                    <Avatar
                        size={'xl'}
                        src={`https://flagcdn.com/48x36/${countryCode?.toLowerCase()}.png`}
                        position={'absolute'} // Position avatar outside the card
                        top={-12} // Move it upwards
                        left={'50%'} // Center horizontally
                        transform={'translateX(-50%)'} // Adjust for exact centering
                        border={'4px solid'} // Optional: Add border for a nice look
                        borderColor={useColorModeValue('white', 'gray.900')} // Matches card background
                    />
                    <Heading fontSize={'2xl'} fontFamily={'body'} mt={2}>
                        {countryData?.name}
                    </Heading>
                </Box>
            </Center>


            <Box mt={8} px={{ base: 0, md: 0 }}>
                <SimpleGrid
                    columns={{ base: 1, sm: 2, md: 3, lg: 4 }}
                    spacing={6}
                >
                    {newsData?.content.map((item, index) => (
                        <Box key={index}>
                            <NewsCard news={item} noOfLines={2} imageHeight="300" />
                        </Box>
                    ))}
                </SimpleGrid>
            </Box>
        </Box>
    );
}

export default CountryNewsPage;
