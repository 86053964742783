import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import {
    Box,
    Flex,
    Text,
    Input,
    Button,
    FormControl,
    FormLabel,
    Stack,
    useBreakpointValue, FormErrorMessage,
} from "@chakra-ui/react";
import { signInUser } from "../../store/auth/authSlice";
import { FormattedMessage } from "react-intl";
import useCustomToast from "../../hooks/useCustomToast";
import { fetchUserDetails } from "../../store/user/userSlice";
import { useAppSelector } from "../../hooks/useAppSelector";
import { RootState } from "../../store/store";
import {validateEmail} from "../../utils/stringUtils";

export const Login = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [emailError, setEmailError] = useState<string | null>(null);
    const [passwordError, setPasswordError] = useState<string | null>(null);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const showToast = useCustomToast();
    const locale = useAppSelector((state: RootState) => state.locale.locale);

    const handleSignInClick = async () => {
        try {
            // Clear previous errors
            setEmailError(null);
            setPasswordError(null);

            if (!email || !password) {
                setPasswordError("allFieldsRequired");
                return;
            }

            if (!validateEmail(email)) {
                setEmailError("invalidEmail");
                return;
            }

            await dispatch(signInUser({ username: email, password })).unwrap();
            const user = await dispatch(fetchUserDetails(locale)).unwrap();

            if (user) {
                if (user.isNewUser === false) {
                    navigate("/");
                } else {
                    navigate("/complete-profile");
                }
                showToast({
                    title: "Success!",
                    description: "Signed in successfully.",
                    status: "success",
                    position: "bottom-right",
                });
            } else {
                throw new Error("User not found");
            }
        } catch (error: any) {

            if(error.name === "UserNotConfirmedException") {
                navigate("/verify", { state: { email } });
                return;
            }
            
            const errorMsg = error.message || "Invalid credentials.";
            setPasswordError(errorMsg);
            setEmailError(" ")
            // showToast({
            //     title: "Error!",
            //     description: errorMsg,
            //     status: "error",
            //     position: "bottom-right",
            // });
        }
    };

    const formSpacing = useBreakpointValue({ base: 4, md: 6 });

    return (
        <Flex
            width="100%"
            justifyContent="center"
            alignItems="center"
        >
            <Flex
                width={{ base: "100%", md: "90%", lg: "80%" }}
                maxWidth="1200px"
                justifyContent="center"
                alignItems="center"
                flexDirection={{ base: "column", md: "row" }}
                bg="white"
                overflow="hidden"
            >
                {/* Left Side: Login Form */}
                <Box flex="1" padding="40px" width={"100%"}>
                    <Text fontSize="2xl" fontWeight="bold" mb={8} textAlign={"left"}>
                        <FormattedMessage id="signin" defaultMessage="Sign In." />
                    </Text>
                    <Stack spacing={formSpacing}>
                        <FormControl id="email" isInvalid={!!emailError}>
                            <FormLabel>
                                <FormattedMessage id="emailAddress" defaultMessage="Email Address" />
                            </FormLabel>
                            <Input
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                focusBorderColor="grey.400"
                            />
                            {emailError && (
                                <FormErrorMessage>
                                    <FormattedMessage id={emailError} />
                                </FormErrorMessage>
                            )}
                        </FormControl>
                        <FormControl id="password" isInvalid={!!passwordError}>
                            <FormLabel>
                                <FormattedMessage id="password" defaultMessage="Password" />
                            </FormLabel>
                            <Input
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                focusBorderColor="grey.400"
                            />
                            {passwordError && (
                                <FormErrorMessage>
                                    <FormattedMessage id={passwordError} />
                                </FormErrorMessage>
                            )}
                        </FormControl>
                        <Stack spacing={2}>
                            <Text
                                fontSize="sm"
                                cursor="pointer"
                                align={"left"}
                                onClick={() => navigate("/forgot-password")}
                            >
                                <FormattedMessage id="forgotPassword" defaultMessage="Forgot Password?" />
                            </Text>
                            <Button
                                bg="black"
                                color="white"
                                size="lg"
                                _hover={{ bg: "gray.800" }}
                                onClick={handleSignInClick}
                            >
                                <FormattedMessage id="signin" defaultMessage="Sign In" />
                            </Button>
                        </Stack>
                    </Stack>
                    <Text textAlign="left" mt={8} fontSize="sm">
                        <FormattedMessage id="newToApp" defaultMessage="Don't have an account?" />
                    </Text>
                    <Text
                        fontWeight="bold"
                        textAlign={"left"}
                        fontSize="sm"
                        cursor="pointer"
                        textDecoration="underline"
                        onClick={() => navigate("/register")}
                    >
                        <FormattedMessage id="createAccount" defaultMessage="Create One Now" />
                    </Text>

                </Box>

                {/* Right Side: Info Section */}
                <Box
                    flex="1"
                    bg="black"
                    padding="40px"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    borderRadius="lg"
                >
                    <Stack spacing={4} mt={20} mb={20}>
                        <Text fontSize="xl" fontWeight="bold" color="white" textAlign="left">
                            <FormattedMessage
                                id="newsAroundYou"
                                defaultMessage="Looking for news around you?"
                            />
                        </Text>
                        <Text color="white" textAlign="left">
                            <FormattedMessage
                                id="seeNewsAroundYouInfo"
                                defaultMessage="See your news even if you are not a registered user."
                            />
                        </Text>
                    </Stack>
                </Box>
            </Flex>
        </Flex>
    );
};
