import React, {useEffect} from 'react';
import {Box, Text, Divider, VStack, HStack, Image} from '@chakra-ui/react';
import { RootState} from "../../store/store";
import { News } from '../../types';
import {fetchLatestNews} from "../../store/latestNews/latestNewsSlice";
import {useAppDispatch} from "../../hooks/useAppDispatch";
import {useAppSelector} from "../../hooks/useAppSelector";
import {useNavigate} from "react-router-dom";
import {FormattedMessage} from "react-intl";

const LatestNews = () => {
    const dispatch = useAppDispatch();
    const locale = useAppSelector((state: RootState) => state.locale.locale);
    const news = useAppSelector((state: RootState) => state.latestNews.data);
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(fetchLatestNews({ locale, page: 0 }));
    }, [dispatch, locale]);

    return (
        <Box mx="auto">
            <VStack align="stretch" marginTop={5} >
                <Text fontWeight="bold"><FormattedMessage id="latestNews" defaultMessage="Latest News"/></Text>
                {news.map((item: News, index: number) => (
                    <React.Fragment key={index}>
                        <HStack
                            spacing={2}
                            align="start"
                            _hover={{ cursor: "pointer" }}
                            onClick={() => navigate(`/news/${item.id}`)}
                        >
                            <Image
                                src={item.imageUrl || '/placeholder.png'}
                                alt={item.title}
                                boxSize={{ base: "50px", md: "70px" }}
                                objectFit="cover"
                                borderRadius="sm"
                                transition="transform 0.2s"
                                _hover={{ transform: "scale(1.05)" }}
                            />
                            <Text
                                noOfLines={3}
                                fontSize={{ base: "sm", md: "sm" }}
                                textAlign="justify"
                            >
                                {item?.title}
                            </Text>
                        </HStack>
                        {index < news.length - 1 && (
                            <Divider borderColor="gray.300" />
                            )}
                    </React.Fragment>
                ))}
            </VStack>

        </Box>
    );
};

export default LatestNews;
