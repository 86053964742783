import {
    Card, CardBody,
    Grid,
    useColorModeValue,
} from "@chakra-ui/react";
import React, {FC, useEffect} from "react";

import {useAppSelector} from "../../../hooks/useAppSelector";
import {useAppDispatch} from "../../../hooks/useAppDispatch";
import {Tag} from "../../../types";
import {RootState} from "../../../store/store";
import {fetchTags, fetchTagsForUser, modifyUserTag} from "../../../store/tags/tagSlice";
import TagBox from "../../../components/TagBox";

interface FollowingTagsProps {}

const FollowingTags: FC<FollowingTagsProps> = () => {
    const bgColor = useColorModeValue('light.cardBackground', 'dark.cardBackground');
    const dispatch = useAppDispatch();
    const selectedTags = useAppSelector((state) => state.tag.selectedTags);

    const tags = useAppSelector((state: RootState) => state.tag.tags);
    const locale = useAppSelector((state: RootState) => state.locale.locale);

    useEffect(() => {
        dispatch(fetchTagsForUser(locale));
    }, [dispatch]);

    const handleTagSelect =  (tag: Tag, isSelected: boolean) => {
        
        console.log(selectedTags)
        const tagCode = tag.code;
        if (isSelected) {
            dispatch(modifyUserTag({actionType: "follow", tagCode}));
            //setSelectedTags([...selectedTags, tag]);
        } else {
            dispatch(modifyUserTag({actionType: "unfollow", tagCode}));
            //setSelectedTags(selectedTags.filter((t) => t !== tag));
        }
    };


    useEffect(() => {
        if (locale && tags.length === 0) {
            dispatch(fetchTags(locale));
        }
    }, [dispatch, locale, tags.length]);

    return (
        <>
            <Grid
                templateColumns={{ base: "repeat(2, 1fr)", md: "1fr 1fr", xl: "repeat(4, 1fr)" }}
                gap="24px"
            >
                {tags.map((t) => (
                    <TagBox
                        key={t.code}
                        tag={t}
                        isSelected={selectedTags.some((selectedTag) => selectedTag.code === t.code)}
                        onSelectTag={handleTagSelect}
                    />
                ))}
            </Grid>
        </>
    );

};

export default FollowingTags;
