import React, { useEffect, FC } from 'react';
import { Avatar, Box, Button, chakra, Flex, Menu, MenuButton, MenuItem, MenuList, Text } from '@chakra-ui/react';
import { useAppDispatch } from "../hooks/useAppDispatch";
import { useAppSelector } from "../hooks/useAppSelector";
import { RootState } from "../store/store";
import { fetchRegions, setRegion } from "../store/region/regionSlice";
import { fetchCategoryFeedNews, resetNewsState } from "../store/categoryNewsFeed/categoryNewsFeedSlice";
import { WorldRegion } from "../types";
import globeIcon from "../assets/svg/globe.svg";

interface WorldRegionPickerProps {
    onRegionChange: (worldRegion: WorldRegion) => void;
}

const RegionPicker: FC<WorldRegionPickerProps> = ({ onRegionChange }) => {
    const dispatch = useAppDispatch();
    const regions = useAppSelector((state: RootState) => state.region.regions);
    const locale = useAppSelector((state: RootState) => state.locale.locale);
    const selectedRegion = useAppSelector((state: RootState) => state.region.currentRegion);

    useEffect(() => {
        if (locale && regions.length === 0) {
            dispatch(fetchRegions(locale));
        }
    }, [dispatch, locale]);

    const handleChange = (region: WorldRegion) => {
        onRegionChange(region);
        dispatch(setRegion(region));
        dispatch(resetNewsState());

        // Fetch category news for the selected region
        dispatch(fetchCategoryFeedNews({ category: 'BUSINESS', locale: 'en', page: 0 }));
        dispatch(fetchCategoryFeedNews({ category: 'TECHNOLOGY', locale: 'en', page: 0 }));
        dispatch(fetchCategoryFeedNews({ category: 'POLITICS', locale: 'en', page: 0 }));
    };

    // Ensure "ALL" is first, then sort the rest alphabetically
    const sortedRegions = [
        { code: "ALL", name: "All" },
        ...regions.filter(region => region.code !== "ALL").sort((a, b) => a.name.localeCompare(b.name))
    ];

    return (
        <Flex alignItems="center">
            <Menu>
                <MenuButton
                    as={Button}
                    variant="unstyled"  // Removes background color
                    minW={0}
                    display="flex"
                    alignItems="center"
                >
                    <chakra.img src={globeIcon} alt="Globe"/>
                </MenuButton>
                <MenuList py={2} borderRadius="md">
                    {sortedRegions.map((region) => (
                        <MenuItem
                            key={region.code}
                            onClick={() => handleChange(region)}
                        >
                            <Text
                                fontSize="15px"
                                fontWeight={selectedRegion?.code === region.code ? "bold" : "normal"}
                            >
                                {region.name}
                            </Text>
                        </MenuItem>
                    ))}
                </MenuList>
            </Menu>
        </Flex>
    );
};

export default RegionPicker;
