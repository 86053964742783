import axios from 'axios';
import {useNavigate} from 'react-router-dom';
import {signOutUser} from "../store/auth/authSlice";
import {store} from "../store/store";
import {getSession} from "./auth";

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080/',
});

// Add a request interceptor
axiosInstance.interceptors.request.use(
    async (config) => {
        const session = await getSession();
        const token = session?.getIdToken().getJwtToken();
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }

        if (!config.headers['Accept-Language']) {
            config.headers['Accept-Language'] = localStorage.getItem('locale') || navigator.language.split(/[-_]/)[0];
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
        
        if (error.response?.status === 401) {
            // Dispatch logout action or clear user state
            store.dispatch(signOutUser());

            // Redirect to the login page
            const navigate = useNavigate();
            navigate('/login');
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;
