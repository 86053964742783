import {Avatar, Button, Flex, Icon, Menu, MenuButton, MenuDivider, MenuItem, MenuList} from "@chakra-ui/react";
import {FormattedMessage} from "react-intl";
import React, {useEffect} from "react";
import { useNavigate } from "react-router-dom";
import {useAppDispatch} from "../../hooks/useAppDispatch";
import {signOutUser} from "../../store/auth/authSlice";
import {useAppSelector} from "../../hooks/useAppSelector";
import {RootState} from "../../store/store";
import {fetchUserDetails} from "../../store/user/userSlice";
import { FaUser, FaPlus, FaCog } from "react-icons/fa";
import { GoSignOut } from "react-icons/go";
import { GiSettingsKnobs } from "react-icons/gi";


export const ProfileButton = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const user = useAppSelector((state: RootState) => state.user.user);
    const locale = useAppSelector((state: RootState) => state.locale.locale);

    const handleNavigation = (path: string) => {
        navigate(path);
    };

    useEffect(() => {
        if (!user) {
            dispatch(fetchUserDetails(locale));
        }
    }, [dispatch, user]);

    const onLogout = () => {
        dispatch(signOutUser())
            .unwrap() // Use .unwrap() to access the fulfilled or rejected value directly
            .then(() => {
                navigate("/"); // Navigate to the home page on successful logout
            })
            .catch((error) => {
                console.error("Logout failed: ", error);
            });
    };

    return (
        <Flex alignItems={'center'} mb={1}>
            <Menu>
                <MenuButton
                    as={Button}
                    rounded={'full'}
                    variant={'link'}
                    cursor={'pointer'}
                    minW={0}>
                    <Avatar
                        size={'sm'}
                        src={
                            user?.profilePicUrl
                        }
                        icon={<Icon as={FaUser} boxSize="5" />}
                    />
                </MenuButton>
                <MenuList py={2}
                          borderBottomRadius="md" // Rounded corners only for bottom
                          borderTopRadius="none" // No rounded corners for top
                >
                    <MenuItem
                        onClick={() => handleNavigation("/profile")}
                        fontWeight="bold"
                        fontSize="15px"
                        color="black">
                        <Icon as={FaUser} boxSize={4} mr={3} />
                        <FormattedMessage id="profile" defaultMessage="Profile" />
                    </MenuItem>
                    <MenuItem
                        onClick={() => handleNavigation("/create-article")}
                        fontWeight="bold"
                        fontSize="15px"
                        color="black">
                        <Icon as={FaPlus} boxSize={4} mr={3} />
                        <FormattedMessage id="create_article" defaultMessage="Create Post" />
                    </MenuItem>
                    <MenuItem
                        onClick={() => handleNavigation("/profile-settings")}
                        fontWeight="bold"
                        fontSize="15px"
                        color="black">
                        <Icon as={GiSettingsKnobs} boxSize={4} mr={3} />
                        <FormattedMessage id="settings" defaultMessage="Settings" />
                    </MenuItem>
                    <MenuItem
                        onClick={onLogout}
                        fontWeight="bold"
                        fontSize="15px"
                        color="black">
                        <Icon as={GoSignOut} boxSize={4} mr={3} />
                        <FormattedMessage id="logout" defaultMessage="Log Out" />
                    </MenuItem>
                </MenuList>
            </Menu>
        </Flex>
    )
}