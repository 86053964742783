import React, { useEffect, useRef } from "react";
import {Box, Image, Text, Heading, Flex, Avatar, Tag, Divider, VStack, Link} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { timeAgo } from "../../utils/dateUtils";
import FollowButton from "../../components/FollowButton";
import BookmarkButton from "../../components/BookmarkButton";
import { News } from "../../types";
import EditorJS from "@editorjs/editorjs";
import { editorConfig } from "../../utils/editorConfig";
import "./editor.css";
import CountryFlag from "../CountryFlag";

interface PreviewProps {
    newsData: News;
    isUserPost: boolean;
}

const Preview: React.FC<PreviewProps> = ({
                                             newsData,
                                             isUserPost,
                                         }) => {
    const navigate = useNavigate();
    const editorRef = useRef<EditorJS | null>(null);
    const hasRun = useRef(false);
    const isPreview = newsData.status === "DRAFT";

    useEffect(() => {
        const initializeEditor = async () => {
            if (hasRun.current) return;

            if (editorRef.current) {
                try {
                    await editorRef.current.isReady;
                    await editorRef.current.destroy();
                } catch (error) {
                    console.error("Error while destroying Editor.js instance:", error);
                }
            }

            if (newsData?.formatting === "MARKDOWN") {
                try {
                    editorRef.current = new EditorJS(editorConfig(newsData.content, true));
                } catch (error) {
                    console.error("Error initializing Editor.js:", error);
                }
            }
            hasRun.current = true;
        };

        initializeEditor();
    }, [newsData]);

    return (
        <Box
            borderRadius={{ base: "0", md: "md" }}
            boxShadow={{ base: "none", md: "md" }}
            w="full"

        >
            {newsData.imageUrl && (
                <Image
                    src={newsData.imageUrl}
                    key={newsData.imageUrl}
                    borderTopRadius={{ base: "0", md: "md" }} // Match the card's top border radius
                    borderBottomRadius="0" // Keep the bottom edges sharp
                    w="full"
                    h={{ base: "150px", md: "400px" }}
                    objectFit="cover"
                />
            )}

            <Heading
                as="h1"
                size={{ base: "lg", md: "xl" }}
                mb={2}
                textAlign="left"
                p={{ base: 2, md: 4 }}
                px={{ base: 2, md: 16 }}
            >
                {newsData.title}
            </Heading>

            <Flex
                align={{ base: "flex-start", md: "center" }} // Left-align on mobile, center-align on larger screens
                justify="space-between"
                width="100%"
                mt={4}
                p={4}
                pl={{ base: 0, md: 16 }} // No left padding on mobile
                pr={{ base: 0, md: 16 }} // No right padding on mobile
                direction={{ base: "column", md: "row" }} // Stack vertically on mobile, horizontal on larger screens
            >
                <Flex align="center" mb={{ base: 4, md: 0 }}> {/* Add margin-bottom on mobile */}
                    <Avatar
                        size="sm"
                        name={isUserPost ? newsData.author : newsData.sourceName}
                        mr={4}
                        src={isUserPost ? newsData.authorIconUrl : newsData.sourceIconUrl}
                    />
                    <Flex direction="column" justify="center">
                        <Text
                            fontSize="sm"
                            fontWeight="medium"
                            textAlign="left"
                            _hover={{ color: "blue.500" }}
                            onClick={() => navigate(newsData?.profileLink || "/")}
                        >
                            {isUserPost ? newsData.author : newsData.sourceName}
                        </Text>
                        <Text fontSize="xs" fontWeight="light" color="black" textAlign="left">
                            {timeAgo(newsData.publishedAt)}
                        </Text>
                    </Flex>
                </Flex>

                <Flex
                    align="center"
                    justify={{ base: "flex-start", md: "flex-end" }} // Align to the left on mobile, right on desktop
                    width={{ base: "100%", md: "auto" }} // Full width on mobile, auto on desktop
                    direction="row" // Keep buttons in a row layout
                    gap={2} // Space between FollowButton and BookmarkButton
                >
                    {newsData.creatorId && (
                        <FollowButton
                            userOrOrgId={newsData.creatorId}
                            type={isUserPost ? "USER" : "ORGANISATION"}
                            isFollowing={newsData.isFollowing ?? false}
                        />
                    )}
                    <BookmarkButton newsId={newsData.id} isBookmarked={newsData.isBookmarked ?? false} />
                    <VStack>
                        {newsData.country && (

                            <CountryFlag
                                country={newsData.country}
                                link={"/news/country/" + newsData.country.cca2}
                            />
                        )}
                    </VStack>
                </Flex>
            </Flex>

            {newsData.formatting === "MARKDOWN" ? (
                <Box id="editorjs" textAlign="justify" p={{ base: 2, md: 4 }} px={{ base: 2, md: 16 }} />
            ) : (
                <Text
                    mb={6}
                    align="justify"
                    p={{ base: 2, md: 4 }}
                    px={{ base: 2, md: 16 }}
                >
                    {newsData.content}
                </Text>
            )}

            {newsData.link &&  <Box pb={2} textAlign="left" mt={4} px={{ base: 2, md: 16 }}>
                <Text
                    as={Link}
                    isExternal={true}
                    href={newsData.link}
                    fontSize="sm"
                    color="gray.500"
                >
                    View Original Article
                </Text>
            </Box>}


            {newsData.tags && newsData.tags.length > 0 && (
                <Flex mt={4} wrap="wrap" px={{ base: 2, md: 16 }}>
                    {newsData.tags.map((tag, index) => (
                        <Tag key={index} mr={2} mb={2}>
                            #{tag.name}
                        </Tag>
                    ))}
                </Flex>
            )}
        </Box>
    );
};

export default Preview;
