import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import {Source} from "../../types";
import {startLoading, stopLoading} from "../loading/loadingSlice";
import {fetchAuthHeaders} from "../../utils/authHeaders";

interface SourceState {
    sources: Source[];
    selectedSources: Source[];
    isLoading: boolean;
    error: string | null;
}

const initialState: SourceState = {
    sources: [],
    selectedSources: [],
    isLoading: false,
    error: null,
};

export const fetchSources = createAsyncThunk(
    'sources/fetchSources',
    async (locale: string, { dispatch, getState, rejectWithValue }) => {
        try {
            dispatch(startLoading());
            const headers = await fetchAuthHeaders(dispatch, getState);
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080/'}api/v1/sources?locale=${locale}`,{
                headers: headers,
            });
            return response.data;
        }
        catch (error: any) {
            return rejectWithValue(error.response.data.message || 'Failed to fetch sources');
        }
        finally {
            dispatch(stopLoading());
        }
    }
);

export const fetchSourcesForUser = createAsyncThunk(
    'sources/fetchSourcesForUser',
    async (locale: string, { dispatch, getState, rejectWithValue }) => {
        try {
            dispatch(startLoading());
            const headers = await fetchAuthHeaders(dispatch, getState);
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080/'}api/v1/sourcesForUser?locale=${locale}`,{
                headers: headers,
            });
            return response.data;
        }
        catch (error: any) {
            return rejectWithValue(error.response.data.message || 'Failed to fetch user sources');
        }
        finally {
            dispatch(stopLoading());
        }
    }
);

export const modifyUserSources = createAsyncThunk(
    'sources/modifyUserSources',
    async ({ sourceCode, actionType }: { sourceCode: string; actionType: 'follow' | 'unfollow' }, { dispatch, getState, rejectWithValue }) => {
        try {
            //dispatch(startLoading());
            const headers = await fetchAuthHeaders(dispatch, getState); // Includes the auth token
            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080/'}api/v1/sources/${sourceCode}/${actionType}`,
                null, // No request body needed
                { headers }
            );
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response?.data?.message || `Failed to ${actionType} source`);
        } finally {
            //dispatch(stopLoading());
        }
    }
);

export const sourceSlice = createSlice({
    name: 'sources',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchSources.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(fetchSources.fulfilled, (state, action: PayloadAction<Source[]>) => {
                state.isLoading = false;
                state.sources = action.payload;
            })
            .addCase(fetchSources.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload as string;
            })
            // Fetch sources for User reducers
            .addCase(fetchSourcesForUser.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(fetchSourcesForUser.fulfilled, (state, action: PayloadAction<Source[]>) => {
                state.isLoading = false;
                state.selectedSources = action.payload;
            })
            .addCase(fetchSourcesForUser.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload as string;
            })
            // Add user to source reducers
            .addCase(modifyUserSources.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(modifyUserSources.fulfilled, (state, action: PayloadAction<Source>) => {
                
                //state.isLoading = false;
                const source = action.payload;

                // Check if the tag is already in selectedTags
                const isSourceSelected = state.selectedSources.some((t) => t.code === source.code);

                // Determine action based on whether the tag is currently selected
                if (isSourceSelected) {
                    // If tag is already selected, remove it (unfollow)
                    state.selectedSources = state.selectedSources.filter((t) => t.code !== source.code);
                } else {
                    // If tag is not selected, add it (follow)
                    state.selectedSources.push(source);
                }
            })
            .addCase(modifyUserSources.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload as string;
            });
    },
});


export default sourceSlice.reducer;
