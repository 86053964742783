import React, {FC, useEffect, useMemo, useState} from 'react';
import {Box, Heading, HStack, Icon, Link, SimpleGrid, useColorModeValue} from '@chakra-ui/react';
import { useAppSelector } from "../../hooks/useAppSelector";
import { RootState } from "../../store/store";
import { groupBy } from 'lodash';
import { useAppDispatch } from "../../hooks/useAppDispatch";
import NewsCard from "../NewsCard/NewsCard";
import { fetchCategoryFeedNews } from "../../store/categoryNewsFeed/categoryNewsFeedSlice";
import SubCategoryNewsGrid from "./SubCategoryNewsGrid";


interface HeadlinesProps {
    category: string;
    numLines?: number;
}

const HeadlinesCardView: FC<HeadlinesProps> = ({ category, numLines = 2 }: HeadlinesProps) => {
    const news = useAppSelector((state: RootState) => state.categoriesNewsFeed.data[category] || []);
    const dispatch = useAppDispatch();
    const locale = useAppSelector((state: RootState) => state.locale.locale);
    // Fetch category feed news on mount
    useEffect(() => {
        dispatch(fetchCategoryFeedNews({ category, locale, page: 0 }));
    }, [dispatch, category, locale]);

    // Memoize grouped and ungrouped lists
    const { mainList, groupedBySubCategory, remainingList } = useMemo(() => {
        const ungroupedListItems = news.filter((item) => item.subCategoryDisplay === null);
        const grouped = groupBy(news.filter((item) => item.subCategoryDisplay !== null), (item) => `${item.subCategoryDisplay}__${item.subCategory}`);

        const sortedKeys = Object.keys(grouped).sort();
        sortedKeys.forEach((key) => {
            if (grouped[key].length > 4) {
                const [topItems, extraItems] = [grouped[key].slice(0, 4), grouped[key].slice(4)];
                grouped[key] = topItems;
                ungroupedListItems.push(...extraItems);
            }
        });

        return {
            mainList: ungroupedListItems.slice(0, 9),
            remainingList: ungroupedListItems.slice(9),
            groupedBySubCategory: grouped,
        };
    }, [news]);

    return (
        <Box>
            <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10} marginTop={5}>
                {mainList.map((item, index) => (
                    <Box key={index}>
                        <NewsCard news={item} noOfLines={numLines} imageHeight={'300'} />
                    </Box>
                ))}
            </SimpleGrid>

            {Object.keys(groupedBySubCategory).sort().map((subCategory, index) => {
                const [subCategoryDisplay, subCategoryCode] = subCategory.split('__');

                return  (
                    <SubCategoryNewsGrid
                        key={index}
                        subCategoryDisplay={subCategoryDisplay}
                        subCategoryCode={subCategoryCode}
                        newsItems={groupedBySubCategory[subCategory]}
                        numLines={numLines}
                    />

                )
            })}

            <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10} marginTop={5}>
                {remainingList.slice(0, 12).map((item, index) => (
                    <Box key={index}>
                        <NewsCard news={item} noOfLines={numLines} imageHeight={'300'} />
                    </Box>
                ))}
            </SimpleGrid>
        </Box>
    );
};

export default React.memo(HeadlinesCardView);
