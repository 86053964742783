import { useState, useEffect, useCallback } from "react";
import axiosInstance from "../utils/axiosInstance";

export interface UseQueryProps<T> {
    url: string;
    page?: {size: number, page: number};
}

export function useQuery<T>({ url, page }: UseQueryProps<T>) {
    const [data, setData] = useState<T | null>(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<Error | null>(null);

    const fetchData = useCallback(async () => {
        setLoading(true);
        try {
            const params = page ? { page: page.page, size: page.size } : {};
            const response = await axiosInstance.get(url, { params });
            if (response.status !== 200) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const json: T = await response.data;
            setData(json);
            setLoading(false);
        } catch (e) {
            setError(e as Error);
            setLoading(false);
        }
    }, [url, page]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const refresh = useCallback(() => {
        fetchData();
    }, [fetchData]);

    return {
        data,
        loading,
        error,
        refresh
    };
}