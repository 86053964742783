import React from 'react';
import { Image, Tooltip } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import {Country} from "../types";

interface CountryFlagProps {
    country: Country;
    link: string;
}

const CountryFlag: React.FC<CountryFlagProps> = ({ country, link }) => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate(link);
    };

    return (
        <Tooltip label={country.name} aria-label="Country Name">
            <Image
                width="24px"
                height="18px"
                src={`https://flagcdn.com/48x36/${country.cca2.toLowerCase()}.png`}
                mr={2}
                cursor="pointer"
                onClick={handleClick}
            />
        </Tooltip>
    );
};

export default CountryFlag;